.dashboard-bookkeeping-component-wrapper {
  min-height: 700px;
}

.dashboard-bookkeeping-component-wrapper .bg-green {
  background-color: #1abc9c;
}

.dashboard-bookkeeping-component-wrapper .bg-blue {
  background-color: rgb(25, 153, 196);
}

.dashboard-bookkeeping-component-wrapper .bg-orange {
  background-color: rgb(219, 128, 54);
}
