.dashboard-component-wrapper {
  min-height: 700px;
}

.dashboard-component-wrapper .dashboard-card {
  border-style: solid;
  border-width: 3px 3px 3px 20px;
  border-color: #2c3e50 !important;
  color: #2c3e50 !important;
  font-weight: bold;
}

.dashboard-component-wrapper .dashboard-card a {
  color: #2c3e50 !important;
}
