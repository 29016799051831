.dashboard-sidenav-component-wrapper .sidenav {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: hsl(209, 29%, 14%);
  overflow: hidden;
  padding-top: 120px;
}

.dashboard-sidenav-component-wrapper .sidenav .sidenav-title {
  color: gray;
  font-size: 16px;
  padding: 10px 10px 20px 30px;
  border-color: gray;
  border-style: none none solid none;
  border-width: 1px;
}

.dashboard-sidenav-component-wrapper .sidenav a {
  padding: 10px 10px 10px 30px;
  text-decoration: none;
  font-size: 16px;
  color: white;
  display: block;
}

.dashboard-sidenav-component-wrapper .sidenav a:hover {
  color: #f1f1f1;
}
